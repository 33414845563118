<style scoped>
.container {
  background-color: white;
}
#tangibles,
#invoice_numbers,
#delivery_order_numbers,
#return_tangibles,
#dispensers{
  white-space: pre;
}
@page {
  size: auto;
}
</style>

<template>
  <div class="container">
    <div id="header">
      <div class="title text-center">
        <h5>JANT MARKETING SDN BHD</h5>
        <h5 class="mt-1">DAILY D/O REPORT</h5>
      </div>
      <div class="row">
        <div class="col">
          <span>DATE: <span id="route_date"></span></span><br />
          <span>DAY: <span id="day_of_week"></span></span><br />
          <span>WAREHOUSE: <span id="from_warehouse"></span></span><br /><br />
        </div>
        <div class="col">
          <span>LORRY: <span id="using_lorry"></span></span><br />
          <span>DRIVER: <span id="for_driver"></span></span><br />
          <span>ASSISTANT: <span id="assistant_drivers"></span></span><br /><br />
        </div>
      </div>
      <!-- <div>
                  <span>Total Records: <span id="daily_route_confirmed_include_orders_length"></span></span>
            </div> -->
    </div>
    <div id="content">
      <table id="id01" class="table table-bordered">
        <thead>
          <tr>
            <!-- <th scope="col" style=""></th> -->
            <th scope="col" style="max-width:8%">A/C CODE</th>
            <th scope="col" style="max-width:8%">INV NO</th>
            <th scope="col" style="max-width:8%">DO NO</th>
            <th scope="col" style="max-width:25%">TANGIBLES</th>
            <th scope="col" style="max-width:25%">DISPENSERS</th>
            <th scope="col" style="max-width:8%">TOTAL PRICE</th>
            <th scope="col" style="max-width:18%">REMARKS</th>
          </tr>
        </thead>
        <tbody>
          <!--for each order in a dr confirmed-->
          <tr v-for="(daily_route_confirmed_include_order, index) in daily_route_confirmed_include_orders" :key="index">
            <!-- <th scope="row">
              <span>{{ index + 1 }}</span>
            </th> -->
            <td>
              <span id="account_number">{{ daily_route_confirmed_include_order.account_number }}</span>
            </td>
            <td>
              <span id="invoice_numbers">{{ daily_route_confirmed_include_order.invoice_numbers }}</span>
            </td>
            <td>
              <span id="delivery_order_numbers">{{ daily_route_confirmed_include_order.delivery_order_numbers }}</span>
            </td>
            <td>
              <span id="tangibles">{{ daily_route_confirmed_include_order.tangibles }}</span>
            </td>
            <td>
              <span id="dispensers">{{ daily_route_confirmed_include_order.dispensers }}</span>
            </td>
            <td><span id="total_price"></span></td>
            <td><span id="remarks"></span></td>
          </tr>
          <tr>
            <!-- <td></td> -->
            <td><strong>TOTAL</strong></td>
            <td></td>
            <td></td>
            <td><span id="total_tangibles"></span></td>
            <!-- <td></td>-->
            <td><span id="total_dispensers"></span></td>
            <td><!-- <span id="grand_total"></span>--></td>
            <td></td>
          </tr>
          <!-- <tr>
                      <td></td>
                      <td><strong>PENDING TANGIBLES</strong></td>
                      <td></td>
                      <td></td>
                      <td><span id="pending_tangibles"></span></td>
                      <td></td>
                      <td></td>
                      <td><span id="grand_total"></span></td>
                      <td></td>
                    </tr> -->
        </tbody>
      </table>
      <br />
      <h6>Return Tangibles</h6>
      <table id="id03" class="table table-bordered">
        <thead>
          <tr>
            <th scope="col" style="max-width:8%">A/C CODE</th>
            <th scope="col" style="max-width:50%">RETURN TANGIBLES</th>
            <th scope="col" style="max-width:42%">REMARKS</th>
          </tr>
        </thead>
        <tbody v-for="(daily_route_confirmed_include_order, index6) in daily_route_confirmed_include_orders" :key="index6">
          <template v-for="order_under_account in daily_route_confirmed_include_order.order_under_account">
            <template v-for="(account_has_returnable_tangible_summary, index5) in order_under_account.account_has_returnable_tangible_summary">
              <tr :key="index5">
                <td>
                  <span id="account_number">{{ daily_route_confirmed_include_order.account_number }}</span>
                </td>
                <td>
                  <span id="return_tangibles">
                    <strong>{{ account_has_returnable_tangible_summary.has_returnable_tangible_summary.min_pending_quantity }}</strong> - {{ account_has_returnable_tangible_summary.name }}<br/>
                  </span>
                </td>
                <td>
                  <span id="remarks"></span>
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
      <br />
      <h6>Sales Return</h6>
      <table id="id02" class="table table-bordered">
        <thead>
          <tr>
            <!-- <th scope="col" style=""></th> -->
            <th scope="col" style="width:5%">A/C CODE</th>
            <th scope="col" style="width:5%">SALESRETURN ID</th>
            <th scope="col" style="width:5%">SALESRETURN NO</th>
            <th scope="col" style="width:35%">TANGIBLES</th>
            <th scope="col" style="width:25%">REMARKS</th>
            <th scope="col" style="width:25%">DRIVER REMARKS</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(daily_route_confirmed_include_sales_return, index2) in daily_route_confirmed_include_sales_returns" :key="index2">
            <!-- <th scope="row">
              <span id="sales_returns_index">{{ index2 + 1 }}</span>
            </th> -->
            <td>
              <span id="sales_returns_account_number">{{ daily_route_confirmed_include_sales_return.account_number }}</span>
            </td>
            <td>
              <span id="sales_returns_id">{{ daily_route_confirmed_include_sales_return.id }}</span>
            </td>
            <td>
              <span id="sales_returns_number">{{ daily_route_confirmed_include_sales_return.sales_return_number }}</span>
            </td>
            <td>
              <span
                v-for="(sales_return_has_tangibles, index3) in daily_route_confirmed_include_sales_return.sales_return_has_tangibles"
                :key="index3"
                id="sales_returns_tangibles"
                ><strong>{{ sales_return_has_tangibles.has_tangibles.quantity }}</strong> - {{ sales_return_has_tangibles.name }}<br
              /></span>
            </td>
            <td>
              <span id="sales_returns_remarks">{{ daily_route_confirmed_include_sales_return.remarks }}</span>
            </td>
            <td><span id=""></span></td>
          </tr>
          <tr>
            <!-- <td></td> -->
            <td><strong>TOTAL</strong></td>
            <td></td>
            <td></td>
            <td><span id="sales_returns_total_tangibles"></span></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

export default {
  components: {},
  props: {},
  data: () => {
    return {
      daily_route_confirmed_include_orders: null,
      daily_route_confirmed_include_sales_returns: null,
      //visibleRows: [],
    };
  },
  computed: {},
  created() {
    console.log(this.$route.query.id);

    //call api for DailyRouteConfirmed - IncludeOrders
    this.$d.request(
      'read',
      'DailyRouteConfirmed',
      {
        id: this.$route.query.id,

        index_withs: false,
        /* with_childs:{
              index_withs: true,
              with_virtual_properties: true,
            }, */

        with_virtual_properties: ['day_of_week', 'using_lorry', 'for_driver', 'assistant_drivers', 'from_warehouse'],
        with_relationships: [
          {
            from_class_name: 'DailyRouteConfirmed',
            relationship_name: 'IncludeOrders',
            with_virtual_properties: ['account_number', 'tangibles', 'dispensers', 'payment_terms', 'total_price', 'invoice_numbers', 'delivery_order_numbers'],
            with_relationships: [
              {
                from_class_name: 'Order',
                relationship_name: 'HasTangibles',
              },
              {
                from_class_name: 'Order',
                relationship_name: 'HasDispensers',
              },
              {
                from_class_name: 'Order', // TODO: only account included in order will appear, if Sales Return the wont appear here
                relationship_name: 'UnderAccount',
                with_relationships: [{
                    from_class_name: "Account",
                    relationship_name: "HasReturnableTangibleSummary",
                }, ],
              },
            ],
          },
          {
            from_class_name: 'DailyRouteConfirmed',
            relationship_name: 'IncludeDeliverableTangibles',
            with_virtual_properties: ['quantity'],
          },
          {
            from_class_name: 'DailyRouteConfirmed',
            relationship_name: 'IncludeSalesReturns',
            with_virtual_properties: ['account_number', 'sales_return_number'],
            with_relationships: [
              {
                from_class_name: 'SalesReturn',
                relationship_name: 'HasTangibles',
              },
            ],
          },
          // {
          //   from_class_name: 'DailyRouteConfirmed',
          //   relationship_name: 'UsingLorry',
          // },
        ],
      },
      class_data => {
        console.log(class_data);

        var route_date = class_data['route_date'];
        document.getElementById('route_date').innerHTML += '<span>' + route_date + '</span>';

        var day_of_week = class_data['day_of_week'];
        document.getElementById('day_of_week').innerHTML += '<span>' + day_of_week + '</span>';

        var using_lorry = class_data['using_lorry'];
        document.getElementById('using_lorry').innerHTML += '<span>' + using_lorry + '</span>';

        var for_driver = class_data['for_driver'];
        document.getElementById('for_driver').innerHTML += '<span>' + for_driver + '</span>';

        var assistant_drivers = class_data['assistant_drivers'];
        document.getElementById('assistant_drivers').innerHTML += '<span>' + assistant_drivers + '</span>';

        var from_warehouse = class_data['from_warehouse'];
        document.getElementById('from_warehouse').innerHTML += '<span>' + from_warehouse + '</span>';

        /* var daily_route_confirmed_include_orders_length = class_data['daily_route_confirmed_include_orders'].length;
            document.getElementById("daily_route_confirmed_include_orders_length").innerHTML += '<span>' + daily_route_confirmed_include_orders_length + '</span>';*/

        var daily_route_confirmed_include_orders = class_data['daily_route_confirmed_include_orders'];
        this.daily_route_confirmed_include_orders = daily_route_confirmed_include_orders;
        console.log(daily_route_confirmed_include_orders);
        var daily_route_confirmed_include_sales_returns = class_data['daily_route_confirmed_include_sales_returns'];
        this.daily_route_confirmed_include_sales_returns = daily_route_confirmed_include_sales_returns;
        console.log(daily_route_confirmed_include_sales_returns);

        if (class_data['daily_route_confirmed_include_sales_returns'].length != 0) {
          //get sales returns
          var o, p;
          var sales_returns_tangibles_quantity = 0;
          var sales_returns_tangibles_name = '';

          // create storage object literal and key/value vars
          var storage3 = {};
          var key3, value3;
          for (o = 0; o < class_data['daily_route_confirmed_include_sales_returns'].length; o++) {
            for (p = 0; p < class_data['daily_route_confirmed_include_sales_returns'][o]['sales_return_has_tangibles'].length; p++) {
              sales_returns_tangibles_quantity =
                class_data['daily_route_confirmed_include_sales_returns'][o]['sales_return_has_tangibles'][p]['has_tangibles']['quantity'];
              sales_returns_tangibles_name = class_data['daily_route_confirmed_include_sales_returns'][o]['sales_return_has_tangibles'][p]['name'];
              console.log(sales_returns_tangibles_quantity + '-' + sales_returns_tangibles_name);

              key3 = sales_returns_tangibles_name;
              value3 = parseInt(sales_returns_tangibles_quantity);

              // if the key exists in storage, add to it
              if (key3 in storage3) {
                storage3[key3].push(value3);
              } else {
                // if the key doesn't exist, create key and add to it
                storage3[key3] = [];
                storage3[key3].push(value3);
              }
            }
          }
          console.log(storage3);
          // iterate storage for answers
          for (var prop3 in storage3) {
            if (storage3.hasOwnProperty(prop3)) {
              // Getting sum of numbers
              var sum3 = storage3[prop3].reduce(function(e, f) {
                return e + f;
              }, 0);
              console.log(sum3 + ' - ' + prop3);

              document.getElementById('sales_returns_total_tangibles').innerHTML += '<span>' + '<strong>' + sum3 + '</strong>' + ' - ' + prop3 + '</span>' + '<br/>';
            }
          }
        } else {
          //document.getElementById("id02").innerHTML += '<tr><td></td><td colspan=' + 6 + '><span>No Records Found.</span></td></tr>';
          document.getElementById('sales_returns_total_tangibles').innerHTML += '<span>No Sales Return Records Available.</span>';
        }

        //calculate grand total price
        /*var i;
            var grand_total_text = 0.00;
            var grand_total = 0.00;
            for (i = 0; i < class_data['daily_route_confirmed_include_orders'].length; i++) {
              grand_total_text = Number(class_data['daily_route_confirmed_include_orders'][i]['total_price']);
              //console.log(grand_total_text);
              grand_total = Number(grand_total + grand_total_text);
            }
            console.log('Grand Total', grand_total);
            document.getElementById("grand_total").innerHTML += '<span>' + Number(grand_total).toFixed(2) + '</span>';*/

        //pending tangibles
        /*var j;
            var tangible_quantity = 0;
            var tangible_name = '';


            for (j = 0; j < class_data['daily_route_confirmed_include_deliverable_tangibles'].length; j++) {

              tangible_quantity = class_data['daily_route_confirmed_include_deliverable_tangibles'][j]['include_deliverable_tangibles']['quantity'];

              tangible_name = class_data['daily_route_confirmed_include_deliverable_tangibles'][j]['name'];

              console.log(tangible_quantity);
              if (tangible_quantity != 0){
                document.getElementById("pending_tangibles").innerHTML += '<span>' + tangible_quantity + ' - ' + tangible_name + '</span>' + '<br/>';
              }

            }*/

        //total tangibles
        var k, l;
        var total_tangible_quantity = 0;
        var total_tangible_name = '';

        // create storage object literal and key/value vars
        var storage = {};
        var key, value;
        for (k = 0; k < class_data['daily_route_confirmed_include_orders'].length; k++) {
          //console.log(class_data['daily_route_confirmed_include_orders'][k]['order_has_tangibles']);
          for (l = 0; l < class_data['daily_route_confirmed_include_orders'][k]['order_has_tangibles'].length; l++) {
            total_tangible_quantity = class_data['daily_route_confirmed_include_orders'][k]['order_has_tangibles'][l]['has_tangibles']['quantity'];
            total_tangible_name = class_data['daily_route_confirmed_include_orders'][k]['order_has_tangibles'][l]['name'];
            console.log(total_tangible_quantity + '-' + total_tangible_name);

            key = total_tangible_name;
            value = parseInt(total_tangible_quantity);

            // if the key exists in storage, add to it
            if (key in storage) {
              storage[key].push(value);
            } else {
              // if the key doesn't exist, create key and add to it
              storage[key] = [];
              storage[key].push(value);
            }
          }
        }
        console.log(storage);
        // iterate storage for answers
        for (var prop in storage) {
          if (storage.hasOwnProperty(prop)) {
            // Getting sum of numbers
            var sum = storage[prop].reduce(function(a, b) {
              return a + b;
            }, 0);
            //console.log(storage[prop] + ' - ' + prop);
            console.log(sum + ' - ' + prop);
            document.getElementById('total_tangibles').innerHTML += '<span>' + '<strong>' + sum + '</strong>' + ' - ' + prop + '</span>' + '<br/>';
          }
        }

        //total dispensers
        var m, n;
        var total_dispenser_quantity = 0;
        var total_dispenser_name = '';

        // create storage object literal and key/value vars
        var storage2 = {};
        var key2, value2;
        for (m = 0; m < class_data['daily_route_confirmed_include_orders'].length; m++) {
          //console.log(class_data['daily_route_confirmed_include_orders'][k]['order_has_tangibles']);
          for (n = 0; n < class_data['daily_route_confirmed_include_orders'][m]['order_has_dispensers'].length; n++) {
            total_dispenser_quantity = class_data['daily_route_confirmed_include_orders'][m]['order_has_dispensers'][n]['has_dispensers']['quantity'];
            total_dispenser_name = class_data['daily_route_confirmed_include_orders'][m]['order_has_dispensers'][n]['name'];
            console.log(total_dispenser_quantity + '-' + total_dispenser_name);

            key2 = total_dispenser_name;
            value2 = parseInt(total_dispenser_quantity);

            // if the key exists in storage, add to it
            if (key2 in storage2) {
              storage2[key2].push(value2);
            } else {
              // if the key doesn't exist, create key and add to it
              storage2[key2] = [];
              storage2[key2].push(value2);
            }
          }
        }
        console.log(storage2);
        // iterate storage for answers
        for (var prop2 in storage2) {
          if (storage2.hasOwnProperty(prop2)) {
            // Getting sum of numbers
            var sum2 = storage2[prop2].reduce(function(c, d) {
              return c + d;
            }, 0);
            //console.log(storage2[prop2] + ' - ' + prop2);
            console.log(sum2 + ' - ' + prop2);
            document.getElementById('total_dispensers').innerHTML += '<span>' + '<strong>' + sum2 + '</strong>' + ' - ' + prop2 + '</span>' + '<br/>';
          }
        }

        setTimeout(() => {
          window.print();
        }, 200);
      },
      (error_message, cancelled) => {
        console.error(error_message);

        if (error_message) {
          // this.error_message = error_message;
        }

        if (cancelled) {
          //do nothign if cancelled
        } else if (!this.error_message) {
          // this.error_message = this.$api.defaultErrorMessage;
        }
      },
      () => {
        // this.loading = false;
      }
    );

    //this._ = _;
  },
  methods: {},
};
</script>
